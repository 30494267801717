
/* awrapper.jsx */
.awrapper-box{
  background: rgba(33, 89, 132, 0.3);

}

/* placedcard.jsx */

.homecart {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
}
.placedcardsbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 2em;
    column-gap: 2em;
    margin-top: 3em;
}
.card {
    width: 12em;
    height: 15em;
    background: rgba(40, 102, 150, 0.3);
    transition: 1s ease-in-out;
    clip-path: polygon(30px 0%  30px, 100% 0px, 100% calc(100% - 0px), calc(100% - 30px) 100%, 0 100%, 0% 30px);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
.card span {
    font-weight: bold;
    color: rgb(61, 60, 60);
    text-align: center;
    display: block;
    font-size: 1.2em;
}
.card .info {
    font-weight: 400;
    color: rgb(120, 118, 118);
    display: block;
    text-align: center;
    font-size: 0.72em;
    margin: 1em;
}
  
  .card .img {
    margin: auto;
  }
  .card .img img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  






/* Allstudentsslider */

.sliderph{
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: flex;
  place-items: center;
  animation: scroll 100s linear infinite;

}
.sliderph:hover{
  animation-play-state: paused;

}
.slide-tracks{
  display: flex;
  width: calc(250px*55);
  
}

@keyframes scroll{
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px*55));
  }
}
.slideph{
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}
.img-sliders{
  width: 80%;
  transition: transform 1s;
}
.img-sliders:hover{
  transform: translateZ(15px);
}
