@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family: "Roboto", sans-serif;
  background-color:rgba(46, 101, 144, 0.7);
}
.container{
  max-width: 85%;
  margin: auto;
}
.flexSB{
  display: flex;
  justify-content: space-between;
}
.icon{
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #eef0f0;
  transition: 0.5s;
}
.icon:hover{
  cursor: pointer;
  background-color: #08211f;
  color: #1a81db;
}
.ff{
  font-family: "Josefin Sans", sans-serif;
}
.fc-white{
  color: #fff;
}
.fc-black{
  color: black;
}
a{
  text-decoration: none;
}
li{
  list-style-type: none;
}
button{
  border: none;
}

#heading{
  text-align: center;
  font-size: 20px;
}
#heading h3{
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 50vh;
  
}



