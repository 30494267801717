#carouselExampleDark {
  position: relative;
  z-index: -100;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.carousel {
  border-radius: 10px;
  overflow: hidden;
}

.carousel-inner img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

.carousel-indicators {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .carousel-inner img {
    height: 300px;
  }
}
