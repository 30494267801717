.review-btn {
    background-color: rgba(239, 239, 240, 0.3);
    width: 2.5rem;
    height: 33vh;
    position: fixed;
    border-radius: 10px;
    filter: drop-shadow(1px 2px 10px black);
    box-shadow: 0px 2px 10px 1px black;
    cursor: pointer;
    z-index: 1000;
    top: 15em;
}
.contentp {
    font-size: 15px;
    transform: rotate(90deg);
}
.reviews-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 20px rgba(0, 0, 0, 0.5);
    padding: 20px;
    overflow-y: scroll;
    z-index: 1000;
}
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
}

.close-btn:hover {
    background-color: darkred;
}


/* whatapp.jsx */

.whatappbtn {
    width: 60px;
    height: 60px;
    position: fixed;
    /* top: 30rem; */
    bottom: 1.4rem;
    right: 2px;
    border-radius: 50%;
    background-color: rgba(233, 231, 231, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    animation: shine 2s infinite;
    z-index: 10000;
  }
  
  .whatappbtn:hover {
    transform: scale(1.1);
  }
  .whatappbtn {
    animation: popup 0.5s ease-in-out, shine 2s infinite;
  }
  
  @keyframes popup {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes shine {
    0% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    }
    100% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    }
  }
  

  



  @media only screen and (max-width: 500px) {
    
  }