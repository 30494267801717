@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

.head {
  padding: 10px;
  color: white;
}

.logo b {
  font-size: 30px;
  font-weight: 900;
}

.head .icon {
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
}

body {
  background-color: #2e5f89;
}

/* Responsive adjustments */
@media only screen and (max-width: 480px) {
  .logo b {
    font-size: 22px;
    font-weight: 700;
  }
  .icon {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}

/*---------------- header---------- --------- */

/* navbar */
.bgc-gray-size {
  background: linear-gradient(
    to bottom right,
    rgba(209, 208, 208, 0.5),
    rgba(169, 168, 168, 0.3)
  );
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: relative;
  z-index: 10;
}

.collapse .nav-item a {
  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300; /* Corrected property */
  font-size: 1.25rem; /* Keep original size */
  transition: color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

.collapse .nav-item a:hover {
  color: rgb(10, 10, 10);
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.bgc-gray-size .navbar-text {
  width: 15em;
  height: 70px;
  position: absolute;
  right: 0.01rem;
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  background-color: #edf0f0;
  border-radius: 0 8px 8px 0; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.bgc-gray-size .navbar-text button {
  color: #2e5f89;
  font-family: "Josefin Sans", sans-serif;
  padding: 10px 20px; /* Add padding */
  border: none;
  border-radius: 5px; /* Rounded corners for button */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.bgc-gray-size .navbar-text button:hover {
  color: rgb(59, 57, 57);
  background-color: rgba(255, 255, 255, 0.2); /* Background change on hover */
}

.navbar-collapse.show {
  margin-top: 4.5em; /* Adjust the margin as per your requirement */
  background-color: rgba(49, 96, 146, 0.9); /* Change to your desired color */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.navbar-toggler {
  position: absolute;
  color: transparent;
  fill: none;
  border: none;
}

@media (max-width: 550px) {
  .bgc-gray-size {
    height: 50px;
  }

  .bgc-gray-size .navbar-text {
    height: 50px;
    width: 12em;
  }

  .navbar-collapse.show {
    margin-top: 3.6em;
  }
}
